import styled from "styled-components";

export const TakeawayLocationsContainer = styled.div`
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fdfdfd;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
`;

export const ScreenTitle = styled.h1`
    text-align: center;
    color: #333;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
`;

export const FormattedList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`;

export const ListItem = styled.li`
    display: flex;
    flex-direction: row; /* Align items horizontally */
    align-items: center; /* Vertically center items */
    background-color: #ffffff;
    padding: 15px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
`;

export const ItemImage = styled.img`
    width: 100px;
    height: 100px;
    border-radius: 8px;
    margin-right: 20px; /* Space between image and text */
    object-fit: cover;
`;

export const ItemDetails = styled.div`
    flex: 1; /* Take up remaining space */
    text-align: left;
`;

export const ItemName = styled.h3<{ color: string }>`
    font-size: 18px;
     color: #${({ color }) => color};
    margin: 0;
`;

export const ItemDescription = styled.p`
    font-size: 14px;
    color: #555;
    margin-top: 8px;
`;

export const ItemSeparator = styled.div`
    height: 1px;
    background-color: #ddd;
    margin: 8px 0;
    width: 100%;
`;

export const AddressAndMap = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
`;

export const Address = styled.div`
  flex: 1;
  font-size: 14px;
  color: #333;
`;

export const MapPreview = styled.div`
  width: 150px;
  height: 100px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }

  iframe {
    border: 0;
  }
`;