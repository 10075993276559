import { useLocation } from 'react-router-dom';
import * as Styled from "./TakeAway.styled";
import {useEffect, useState} from "react";
import {UserAddressI, UserI} from "../../../user/userInterfaces";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {selectUser, setUserAddress, setUserAddresses,setUserCompletedOrders} from "../../../user/userSlice";
import {selectLocale, setAvailableLocales} from "../../../intl/intlSlice";
import {getAvailableLocales} from "../../../intl/intlRepository";
import {UserStatus} from "../../../user/userInterfaces";
import {addOrderToPastOrders} from "../../../cart/ordersSlice";
import {setVendorColor} from "../../../theme/themeSlice";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import {
    getTableDetails,
    getVendor,
    getVendorButtons, getVendorPaymentMethods,
    getVendorSchedule
} from "../../../vendor/vendorRepository";
import {setFavourites, setItemSelected, setMenu, setMenuList} from "../../../menu/menuSlice";
import {getMenu} from "../../../menu/menuRepository";
// import {selectVendor} from "../../../vendor/vendorSlice";
import {    prepareUserLastCart,
    userCompletedOrders,
    userFavouriteOutlets,
    userFavouriteProducts,deleteUserAddress, listUserAddresses} from "../../../user/userRepository";
import {useNavigate} from "react-router-dom";

import {lastOrder} from "../../../cart/cartRepository";
import {
    selectVendor,
    setTableInfo,
    setVendor,
    setVendorButtons, setVendorFavourites, setVendorPaymentMethods,
    setVendorSchedule,
    unsetVendor
} from "../../../vendor/vendorSlice";
import {clearCart} from "../../../../features/cart/cartSlice";

// import {useAppDispatch, useAppSelector} from "../../../../app/hooks";

// import {selectUser, setUserCompletedOrders} from "../../../user/userSlice";

// import {UserI, UserStatus} from "../../../user/userInterfaces";

import { Vendor } from "../../../vendor/Vendor.js";
interface TakeAwayLocationsState {
    chains: Vendor[];
}




export function TakeAwayLocations() {
    const [isLoading, setIsLoading] = useState(false);
    const locale = useAppSelector(selectLocale);
    const location = useLocation();
    const { chains } = location.state as TakeAwayLocationsState;
    const user = useAppSelector(selectUser);

    const vendor = useAppSelector(selectVendor);
    const dispatch = useAppDispatch();
       // const addresses = useAppSelector(selectUserAddresses);
       const handleMapClick = (address : string) => {
        const encodedAddress = encodeURIComponent(address);
        const googleMapsURL = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
        window.open(googleMapsURL, "_blank");
      };
       const doResets = () => {
              dispatch(setItemSelected(null));
              dispatch(unsetVendor);
              dispatch(clearCart());
              localStorage.removeItem('load');
          }
    const navigate = useNavigate();
    const handleChainClick = (chain : Vendor, user : UserI) => {
        console.log("Chain clicked:", chain);
        fetchChainData(chain, user)
            .then(() => {
                doResets();
                navigate('/menu'); // After the data is fetched, navigate to the menu screen
            })
            .catch((error) => {
                console.error("Error fetching chain data:", error);
            });
    };
    const fetchChainData = (chain: Vendor, user: UserI): Promise<void> => {
        return new Promise((resolve, reject) => {
            setIsLoading(true);  // Start loading
            Promise.all([
                getMenu(chain.OutletID ?? "", locale.LanguageID, user.RegisteredUserID),
                getVendorButtons(chain.OutletID ?? ""),
                getAvailableLocales(),
                getTableDetails(chain.TableID ?? ""),
                getVendorSchedule(chain.OutletID ?? ""),
                userFavouriteProducts(user.RegisteredUserID, chain.OutletID ?? ""),
                userFavouriteOutlets(user.RegisteredUserID),
                userCompletedOrders(user.RegisteredUserID, chain.OutletID ?? ""),
                getVendorPaymentMethods(chain.OutletID ?? ""),
                lastOrder(user.RegisteredUserID, chain.OutletID ?? ""),
                getVendor(chain.OutletID ?? ""),
            ])
            .then(res => {
                dispatch(setMenu(res[0].catalog));
                dispatch(setMenuList(res[0].list));
                dispatch(setVendorButtons(res[1]));
                dispatch(setAvailableLocales(res[2]));
                dispatch(setTableInfo(res[3]));
                dispatch(setVendorSchedule(res[4]));
                dispatch(setFavourites(res[5]));
                dispatch(setVendorFavourites(res[6]));
                dispatch(setUserCompletedOrders(res[7].length));
                dispatch(setVendorPaymentMethods(res[8]));
    
                if (res[9] && user.Verified === UserStatus.Verified) {
                    dispatch(addOrderToPastOrders(prepareUserLastCart(res[9], res[0].list)));
                }
    
                dispatch(setVendor(res[10]));
                dispatch(setVendorColor(res[10].Color ?? 'E84D39'));
                setIsLoading(false);  // Stop loading
                resolve(); // Successfully fetched all data
            })
            .catch(e => {
                setIsLoading(false);  // Stop loading in case of error
                reject(e);
            });
        });
    };
    return (
        <Styled.TakeawayLocationsContainer>
            <Styled.ScreenTitle>Select Location:</Styled.ScreenTitle>
            {/* Show loading indicator if loading */}
            {isLoading ? (
            <div className="flex items-center justify-center h-screen flex-col">
                <AiOutlineLoading3Quarters size={50} className="animate-spin" color="var(--theme-primary-color)"/>
                <br/>
                <div>loading...</div>
            </div>
        ) : (
            <Styled.FormattedList>
                {chains.map(chain => (
                    <Styled.ListItem key={chain.OutletID} onClick={() => handleChainClick(chain, user)}>
                    <Styled.ItemImage style={{ color: chain.Color || '#E84D39' }}  
                        src={`https://ninastimages.blob.core.windows.net/images/outletsLogos/${chain.FileName}`} 
                        alt={chain.Name} 
                    />
                    <Styled.ItemDetails>
                        <Styled.ItemName color={chain.Color || '#E84D39'}>
                            {chain.Name}
                        </Styled.ItemName>
                        <Styled.ItemDetails>
                            {chain.Address || "-"}
                        </Styled.ItemDetails>
                        <Styled.ItemDetails>
                            {chain.City || "-"}
                        </Styled.ItemDetails>
                        <Styled.ItemDetails>
                            {chain.PostalCode || "-"}
                        </Styled.ItemDetails>
                        <Styled.ItemSeparator/>
                        <Styled.ItemDescription>
                            {chain.Description || "-"}
                        </Styled.ItemDescription>
                    </Styled.ItemDetails>
                </Styled.ListItem>
                ))}
            </Styled.FormattedList>
        )}
        </Styled.TakeawayLocationsContainer>
        
    );
}