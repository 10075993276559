import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {getVendorStatus} from "../vendorRepository";
import {useEffect} from "react";
import {selectVendor, setVendorOpenStatus} from "../vendorSlice";
import {FaDotCircle} from "react-icons/fa";
import {isDeliveryAvailable, isTakeAwayAvailable} from "../../cart/cartService";
import {selectCart, selectCartType} from "../../cart/cartSlice";
import {CartType} from "../../cart/cartInterfaces";
export function VendorOpenStatusPill(){
    const vendor = useAppSelector(selectVendor);
    const dispatch = useAppDispatch()
    const cartSliceState = useAppSelector(selectCart);

    let description = "";
    let color = "";

    if (cartSliceState.type === CartType.TakeAway) {
        description = isTakeAwayAvailable(vendor) ? "Open" : "Closed";
        color = isTakeAwayAvailable(vendor) ? "green" : "red";
    } else if (cartSliceState.type === CartType.Delivery) {
        description = isDeliveryAvailable(vendor) ? "Open" : "Closed";
        color = isDeliveryAvailable(vendor) ? "green" : "red";
    }

    return (
        <p className="mb-3">
            
          
            <FaDotCircle  color={color} className="inline-block mr-3"/>
            {description}
            
        </p>
    )
}
