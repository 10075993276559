import * as styled from './UserAddresses.styled';
import {ActionButton} from "../../../theme/styled-components/UI.styled";
import {useEffect, useState} from "react";
import {BsChatRightDots} from "react-icons/bs";
import {IoSettingsOutline} from "react-icons/io5";
import {UserAddressI} from "../../userInterfaces";
import {Menu, Transition, Popover} from '@headlessui/react'
import {MdEditNote} from "react-icons/md";
import {RiDeleteBinLine} from "react-icons/ri";
import {Fragment} from "react";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {selectUser, setUserAddress, setUserAddresses,setUserCompletedOrders} from "../../userSlice";
import {selectLocale, setAvailableLocales} from "../../../intl/intlSlice";
import {getAvailableLocales} from "../../../intl/intlRepository";
import {UserStatus} from "../../../user/userInterfaces";
import {addOrderToPastOrders} from "../../../cart/ordersSlice";
import {setVendorColor} from "../../../theme/themeSlice";
import {
    getTableDetails,
    getVendor,
    getVendorButtons, getVendorPaymentMethods,
    getVendorSchedule
} from "../../../vendor/vendorRepository";
import {setFavourites, setItemSelected, setMenu, setMenuList} from "../../../menu/menuSlice";
import {getMenu} from "../../../menu/menuRepository";
import {EstablishmentI} from "../../../vendor/vendorInterfaces";
import {getQrOutlets} from "../../../vendor/vendorRepository";
// import {selectVendor} from "../../../vendor/vendorSlice";
import {    prepareUserLastCart,
    userCompletedOrders,
    userFavouriteOutlets,
    userFavouriteProducts,deleteUserAddress, listUserAddresses} from "../../userRepository";
import {useNavigate} from "react-router-dom";
import {FormButton} from "../../../theme/styled-components/Form.styled";
import {clearCart} from "../../../../features/cart/cartSlice";
import {lastOrder} from "../../../cart/cartRepository";
import {
    selectVendor,
    setTableInfo,
    setVendor,
    setVendorButtons, setVendorFavourites, setVendorPaymentMethods,
    setVendorSchedule,
    unsetVendor
} from "../../../vendor/vendorSlice";

interface Props {
    address: UserAddressI
}

export function AddressCard({address}: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const user = useAppSelector(selectUser);
    const vendor = useAppSelector(selectVendor);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

   const doResets = () => {
              dispatch(setItemSelected(null));
              dispatch(unsetVendor);
              dispatch(clearCart());
              localStorage.removeItem('load');
          }
    const locale = useAppSelector(selectLocale);


    const deleteAddress = () => {
        deleteUserAddress(user.RegisteredUserID, address.LocationID)
            .then(res => {
                listUserAddresses(user.RegisteredUserID, vendor.OutletID)
                    .then(res => {
                        dispatch(setUserAddresses(res));
                    })
            })
    }
    const editAddress = () => {
        navigate('/address/edit/'+address.LocationID)
    }

    const selectAddress = () => {

        if (vendor.OutletID != address.OutletID){
            doResets();
        }

        dispatch(setUserAddress(address));
        console.log("Selected: "+ address.OutletID);
        return new Promise((resolve, reject) => {
            setIsLoading(true);
         Promise.all([
                        getMenu(address.OutletID ?? "", locale.LanguageID,user.RegisteredUserID),
                        getVendorButtons(address.OutletID ?? ""),
                        getAvailableLocales(),
                        getTableDetails(address.TableID ?? ""),
                        getVendorSchedule(address.OutletID ?? ""),
                        userFavouriteProducts (user.RegisteredUserID, address.OutletID ?? ""),
                        userFavouriteOutlets(user.RegisteredUserID),
                        userCompletedOrders(user.RegisteredUserID, address.OutletID ?? ""),
                        getVendorPaymentMethods(address.OutletID ?? ""),
                        lastOrder(user.RegisteredUserID, address.OutletID ?? ""),
                        getVendor(address.OutletID ?? "" ),
                    ]).then(res => {
                        dispatch(setMenu(res[0].catalog));
                        dispatch(setMenuList(res[0].list));
                        dispatch(setVendorButtons(res[1]));
                        dispatch(setAvailableLocales(res[2]));
                        dispatch(setTableInfo(res[3]));
                        dispatch(setVendorSchedule(res[4]));
                        dispatch(setFavourites(res[5]));
                        dispatch(setVendorFavourites(res[6] ));
                        dispatch(setUserCompletedOrders(res[7].length));
                        dispatch(setVendorPaymentMethods(res[8]));
                        if(res[9] && user.Verified === UserStatus.Verified){
                            dispatch(addOrderToPastOrders(prepareUserLastCart(res[9], res[0].list)))
                        }
                        dispatch(setVendor(res[10]))
                        dispatch(setVendorColor(res[10].Color ?? 'E84D39'))
                        setIsLoading(false);  // Stop loading

                        navigate('/menu');

                    }).catch(e => {
                        setIsLoading(false);  // Stop loading in case of error
                        reject(e);
                    });
                })
       
    }

    return (
      
        <styled.AddressCard>
              {isLoading ? (
            <div>Loading...</div>  // Or replace with a spinner
        ): ""}
            <div className="header">
                <div className="actions">


                    <Menu as="div" className="relative inline-block text-left">

                        
                        <Menu.Button>
                            <ActionButton>
                                <IoSettingsOutline size="16"/>
                            </ActionButton>
                        </Menu.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items
                                className="absolute left-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="px-1 py-1 ">
                                    <Menu.Item>
                                        {({active}) => (
                                            <button
                                                onClick={editAddress}
                                                className={`group flex w-full items-center rounded-md px-2 py-2 text-sm ${active ? 'bg-sky-100' : 'text-gray-900'}`}
                                            >
                                                <MdEditNote size={24} className={'mr-4'}/>
                                                <span className="text-lg">Edit</span>
                                            </button>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({active}) => (
                                            <button
                                                onClick={deleteAddress}
                                                className={`group flex w-full items-center rounded-md px-2 py-2 text-sm ${active ? 'bg-sky-100' : 'text-gray-900'}`}
                                            >
                                                <RiDeleteBinLine size={20} className={'mr-4 text-red-600'}/>
                                                <span className="text-lg text-red-600">Delete</span>
                                            </button>
                                        )}
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Transition>

                    </Menu>
        
                </div>
                <div className="status">
                    {address.ValidForDelivery === "1" &&
                        <span className="text-green-500 font-medium">Delivery Available!</span>
                    }
                    {address.ValidForDelivery !== "1" &&
                        <span className="text-gray-400">Delivery Unavailable!</span>
                    }
                </div>
                <div className="comments">
                    {address.Details &&
                        <Popover className="relative">
                            {({ open }) => (
                                <>
                                    <Popover.Button>
                                        <ActionButton>
                                            <BsChatRightDots size="16"/>
                                        </ActionButton>
                                    </Popover.Button>

                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-200"
                                        enterFrom="opacity-0 translate-y-1"
                                        enterTo="opacity-100 translate-y-0"
                                        leave="transition ease-in duration-150"
                                        leaveFrom="opacity-100 translate-y-0"
                                        leaveTo="opacity-0 translate-y-1"
                                    >
                                        <Popover.Panel className="
                                absolute
                                w-64
                                right-0
                                z-10 mt-3
                                lg:-translate-y-0.5
                                transform
                                px-4
                                sm:px-0
                                lg:max-w-1xl">
                                            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                                <div className="relative bg-white p-7">
                                                    {decodeURIComponent(address.Details?? "")}
                                                </div>
                                            </div>
                                        </Popover.Panel>
                                    </Transition>
                                </>
                            )}
                        </Popover>
                    }


                </div>
            </div>
             {/* Show loading indicator if loading */}
       
            <div className="content">
                <div className="flex-grow">
                    <p>{address.District}, {address.PostalCode}</p>
                    <p>{address.RoadName} {address.Number}</p>
                    <p>{address.BuildingName}</p>
                    {address.FloorNumber &&
                        <p>Floor {decodeURIComponent(address.FloorNumber)} #{address.FlatNo}</p>
                    }
                </div>
                <FormButton
                    disable={address.ValidForDelivery !== "1"}
                    className={'mt-4'}
                    background={'var(--theme-sky-blue)'}
                    onClick={selectAddress}>
                    {address.ValidForDelivery === "1" ? 'Select' : 'Unavailable'}
                </FormButton>

            </div>
       
        </styled.AddressCard>
 
    )
}
